import { ArrowRightAlt } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from "./Styles.module.scss";
import slide1 from '../../../../../src/img/slide1.jpg';
import slide2 from '../../../../../src/img/slider 2.jpg';
import slide3 from '../../../../../src/img/slider 3 n.jpg';

const SideBanner = () => {
    return (
        <Box component="div" className={styles.side}>
            <Box component="div" sx={{display: "flex", position: "relative"}}>
                <Box className={styles.sideBannerText}>
                    <h4>Թոփ ապրանք</h4>
                    <h3>Վերմակներ<br />Ամառային</h3>
                    <Link to="/products/9/vermakner-amarhayin">Գնել հիմա <ArrowRightAlt sx={{ml: 1, fontSize: 22}} /></Link>
                </Box>
                <Box component={"img"} src={slide1} alt="banner" />
            </Box>
            <Box component="div" sx={{display: "flex", position: "relative"}}>
                <Box className={styles.sideBannerText}>
                    <h4 style={{marginBottom: 0}}>Թոփ ապրանք</h4>
                    <h3 style={{marginBottom: 0}}>Բարձեր</h3>
                    <p style={{lineHeight: 1.6}}>Խնայիր ֏500</p>
                    <Link to="/products/8/bardzer">Գնել հիմա <ArrowRightAlt sx={{ml: 1, fontSize: 22}} /></Link>
                </Box>
                <Box component={"img"} src={slide2} alt="banner" />
            </Box>
            <Box component="div" sx={{ display: "flex", position: "relative", backgroundColor: "#f0f0f0" }}>
                <Box className={styles.sideBannerText}>
                    <h4 style={{marginBottom: 0}}>Թոփ ապրանք</h4>
                    <h3 style={{marginBottom: "0.2rem"}}>Ծածկոցներ</h3>
                    <p style={{lineHeight: 1}}>Ամենաթեժ առաջարկ</p>
                    <Link to="/products/12/tsatskots">Գնել հիմա <ArrowRightAlt sx={{ml: 1, fontSize: 22}} /></Link>
                </Box>
                <Box component={"img"} src={slide3} alt="banner" />
            </Box>
        </Box>
    );
}

export default SideBanner;
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { FaFacebookF, FaInstagram, FaYoutube, FaTelegramPlane, FaWhatsapp } from 'react-icons/fa';

const SocialAppBar = () => {
  const handleSocialIconClick = (url) => {
    window.open(url, '_blank');
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ bgcolor: '#fff' }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ width: 24 }} /> {/* Invisible Box as a Spacer */}
          <Box sx={{ display: 'flex', gap: 4 }}> {/* Centered Social Icons */}
            <IconButton color="#176e2a" onClick={() => handleSocialIconClick('https://www.facebook.com/bedder.shop')}>
              <FaFacebookF />
            </IconButton>
            <IconButton color="#176e2a" onClick={() => handleSocialIconClick('https://www.instagram.com/beddingshop.am/')}>
              <FaInstagram />
            </IconButton>
            <IconButton color="#176e2a" onClick={() => handleSocialIconClick('https://www.youtube.com/@BeddingShopam/featured')}>
              <FaYoutube />
            </IconButton>
            <IconButton color="#176e2a" onClick={() => handleSocialIconClick('https://t.me/BeddingShopam')}>
              <FaTelegramPlane />
            </IconButton>
            <IconButton color="#176e2a" onClick={() => handleSocialIconClick('https://api.whatsapp.com/send?phone=37499111040')}>
              <FaWhatsapp />
            </IconButton>
          </Box>
          <Box sx={{ width: 24 }} /> {/* Invisible Box as a Spacer */}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default SocialAppBar;

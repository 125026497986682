import React from 'react';
import Rating from '@mui/material/Rating';

const DeviceRating = ({ value, onChange }) => {
  return (
    <div>
      <Rating name="read-only" size='small' value={value} onChange={(event, newValue) => onChange(newValue)} />
    </div>
  );
}

export default DeviceRating;
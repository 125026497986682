import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { updateDevice } from '../../http/deviceAPI'; // Функция API для обновления данных устройства

const EditDevice = ({ show, onHide, device }) => {
    const [name, setName] = useState('');
    const [price, setPrice] = useState(0);

    useEffect(() => {
        if (device) {
            setName(device.name);
            setPrice(device.price);
        }
    }, [device]);

    const handleSave = async () => {
        const updatedData = {
            name: name,
            price: price,
        };

        try {
            const updatedDevice = await updateDevice(device.id, updatedData);
            console.log('Device updated successfully:', updatedDevice);
            onHide(); // Close the modal on successful update
        } catch (error) {
            console.error('Error updating device:', error);
        }
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Edit Device</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter name"
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Price</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Enter price"
                            value={price}
                            onChange={e => setPrice(Number(e.target.value))}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Close</Button>
                <Button variant="primary" onClick={handleSave}>Save Changes</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditDevice;
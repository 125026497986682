import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import img from "../assets/bigslider 1.jpg"
import img2 from "../assets/bigslider 2.jpg"
import img3 from "../assets/bigslider 3.jpg"


const BigSlider = () => {
    return (
        <div className="carousel-container">
              <Carousel>
                <Carousel.Item interval={2000}>
                    <img
                        className="d-block w-100 .img-fluid."
                        src={img2}
                        alt="First slide"
                    />
                    <Carousel.Caption>
                        <h3>Անկողնային պարագաներ</h3>
                        <p>Այսպիսի գլխապտույտ առաջարկ կարող եք տեսնել միայն Beddingshop.am առցանց խանութում։</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                    <img
                        className="d-block w-100 .img-fluid."
                        src={img}
                        alt="First slide"
                    />
                    <Carousel.Caption>
                        <h3>Անկողնային պարագաներ</h3>
                        <p>Թարմացրեք ձեր անկողնային պարագաների հավաքածուն այսօր և վայելեք առավելագույն հարմարավետությունն ու ոճը անգերազանցելի գներով:</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                    <img
                        className="d-block w-100 .img-fluid."
                        src={img3}
                        alt="First slide"
                    />
                    <Carousel.Caption>
                        <h3>Անկողնային պարագաներ</h3>
                        <p>Ամենաորակյալ և նորաձև անկողնային պարագաները առաջինը կարող եք ձեռք բերել BeddingShop.am առցանց խանութում։</p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </div>
    );
};

export default BigSlider;

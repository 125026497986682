import {$authHost, $host} from "./index";

export const createType = async (type) =>{
    const {data} = await $authHost.post('api/type', type)
    return data

}
export const fetchTypes = async () =>{
    const {data} = await $host.get('api/type')
    return data

}

export const createBrend = async (brend) =>{
    const {data} = await $authHost.post('api/brand', brend)
    return data

}
export const fetchBrends = async () =>{
    const {data} = await $host.get('api/brand' ,{params: {

        }})
    return data

}

export const createDevice = async (device) =>{
    
    const {data} = await $authHost.post('api/device', device)
    return data

}
export const fetchDevice = async (typeId, brendId, page, limit=5) =>{
    const {data} = await $host.get('api/device', {params:{
            typeId, brendId, page, limit
        }})
    return data

}
export const fetchOneDevice = async (id) =>{
    const {data} = await $host.get('api/device/' + id)
    return data

}

export const updateDevice = async (id, deviceData) => {
    try {
        const { data } = await $authHost.put(`api/device/${id}`, deviceData);
        return data;
    } catch (error) {
        console.error('Failed to update device:', error);
        throw error;
    }
};

export const deleteOne = async (id) =>{
    const {data} = await $host.delete('api/device/' + id)
    return data

}

export const deleteOneType = async (id) => {
    try {
        const { data } = await $host.delete('api/type/' + id);
        return data;
    } catch (error) {
        throw error;
    }
};

export const deleteOneBrand = async (id) => {
    try {
        const { data } = await $host.delete('api/brand/' + id);
      return data;
    } catch (error) {
      throw error;
    }

    
  };
  export const fetchDevicesByType = async (typeId, brendId, page, limit = 5) => {
    try {
      const { data } = await $host.get("api/device", {
        params: { typeId, brendId, page, limit },
      });
      return data;
    } catch (error) {
      throw error;
    }
  };
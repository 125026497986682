import React, {useContext, useEffect, useState} from 'react';
import {Button, Card, Container, Col, Row} from "react-bootstrap";
import CreateBrend from "../components/modals/CreateBrend";
import CreateDevice from "../components/modals/CreateDevice";
import CreateType from "../components/modals/CreateType";
import {Context} from "../index";
import { Box } from '@mui/system';
import { CardContent, CardMedia, MenuItem } from '@mui/material';
import { MdAddShoppingCart } from 'react-icons/md';
import { GiBinoculars } from 'react-icons/gi';
import { Link, useNavigate } from 'react-router-dom';
import styles from "../components/DeviceItemCard.module.scss";
import {DEVICE_ROUTE} from "../utils/consts";
import { deleteOne } from '../http/deviceAPI';
import { deleteOneType } from '../http/deviceAPI';
import { deleteOneBrand } from '../http/deviceAPI';
import EditDevice from "../components/modals/EditDevice";




const Admin = () => {
    const navigate = useNavigate();
    

    const {device} = useContext(Context)
    const [brendVisible, setBrendVisible] = useState(false)
    const [typeVisible, setTypeVisible] = useState(false)
    const [deviceVisible, setDeviceVisible] = useState(false)
    const [editDeviceVisible, setEditDeviceVisible] = useState(false); // Состояние для видимости модали редактирования
    const [currentDevice, setCurrentDevice] = useState(null); // Состояние для текущего редактируемого устройства
    
    const goHome = () => {
        navigate(DEVICE_ROUTE + '/' + device.id);
    };
    const deleteItems = (device) => {
        deleteOne(device.id);
    }
    useEffect(()=>{
        
    },[])

    const deleteType = async (type) => {
        try {
            // Вызывайте функцию deleteOneType с ID типа
            const response = await deleteOneType(type.id);
            console.log(response); // Ожидаемое сообщение об успешном удалении
        } catch (error) {
            console.error('Error deleting type:', error);
            // ...
        }
    };
    const deleteBrend = async (brend) => {
        try {
            const response = await deleteOneBrand(brend.id);
            console.log(response); // Ожидаемое сообщение об успешном удалении
        } catch (error) {
            console.error('Error deleting brend:', error.message);
            if (error.response) {
                console.error('Axios Status Code:', error.response.status);
            }
        }
    };
    const openEditModal = (device) => {
        setCurrentDevice(device); // Установка текущего устройства для редактирования
        setEditDeviceVisible(true); // Показ модального окна редактирования
    };
    useEffect(()=>{
        
    },[])
    return (
        <>
        <Container className="d-flex flex-column">

            <Button
                variant={"outline-dark"}
                className="mt-2"
               onClick={()=> setTypeVisible(true)}
            >
                Ավելացնել կատեգորիա
            </Button>
            <Button
                variant={"outline-dark"}
                className="mt-2"
                onClick={()=> setBrendVisible(true)}

            >
                Ավելացնել բրենդ-որակ
            </Button>
            <Button
                variant={"outline-dark"}
                className="mt-2"
                onClick={()=> setDeviceVisible(true)}
            >
                Ավելացնել ապրանք
            </Button>

            <CreateBrend show={brendVisible} onHide={() =>setBrendVisible(false) }/>
            <CreateDevice show={deviceVisible} onHide={() =>setDeviceVisible(false)}/>
            <CreateType show={typeVisible} onHide={() =>setTypeVisible(false)}/>
            <EditDevice show={editDeviceVisible} onHide={() => setEditDeviceVisible(false)} device={currentDevice} />
        </Container>
        <Container>
    <div className="badding-items-container">
      
      
      <Col md={12}>
          
        
    
        <Row className="d-flex">
            {device.devices.map(device =>
                <Card
                style={{ boxShadow: 'none', width: 'auto', ...device.sty }}
                sx={{ height: '100%' }}
                className={`${styles.card} card`}
                
                
            >
                {/* <Button variant="primary" onClick={() => openEditModal(device)}>Редактировать</Button> // Кн */}
                <Box
                    component="div"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                    }}
                    className={styles.imageDiv}
                >
                    <CardMedia
                        component="img"
                        image={process.env.REACT_APP_API_URL + device.img}
                        onClick={goHome}
                        alt="green iguana"
                        sx={{ width: '100%' }}
                    />
    
                    <div>
                    <div className={styles.bottom}>
                        <div className={styles.content}>
                            <div productid={device.id} onClick={() => deleteItems(JSON.parse(JSON.stringify(device)))}>
                                <MdAddShoppingCart />
                                <h5>delete</h5>
                            </div>
                            <div className={styles.rode}></div>
                            <Link  onClick={() => openEditModal(device)}>
                                <GiBinoculars />
                                <h5>Edit</h5>
                            </Link>
                        </div>
                    </div>
                    </div>
                </Box>
                <CardContent className={styles.cardContent}>
                    <div onClick={goHome} className={styles.title}>
                        {device.name}
                    </div>
                    <p
                        style={device?.children && { color: '#EF837B' }}
                        className={styles.price}
                    >
                        ${device.price} {device?.children && device.children}
                    </p>
                </CardContent>
            </Card>
            )}

        </Row>
        </Col>
    </div>
    
    {device?.types.map((type) => (
    <div key={type.id} style={{ display: 'flex', alignItems: 'center' }}>
        <MenuItem
            style={{ cursor: 'pointer' }}
            
        >
            {type.name}
        </MenuItem>
        <Button
            variant="danger"
            size="sm"
            productid={type.id}
            onClick={() => deleteType(type)}
            style={{ marginLeft: '10px' }}
        >
            Удалить
        </Button>
        
    </div>
))}

{device &&
        device.brends.map((brend) => (
          <Card
            style={{ cursor: 'pointer' }}
            key={brend.id}
          >
            {brend.name}
            
            <Button
              variant="danger"
              size="sm"
              className="ml-2"
              productid={brend.id}
              onClick={() => deleteBrend(brend)}
              
              
            >
              Delete
            </Button>
          </Card>
        ))}

        
    


    </Container>
    
    
</>

    );
};

export default Admin;
import React, {useState} from 'react';
import {Card,} from "react-bootstrap";

import {useNavigate} from "react-router-dom"
import {DEVICE_ROUTE} from "../utils/consts";
import {MdAddShoppingCart} from "react-icons/md";
import {CardContent, CardMedia} from "@mui/material";
import {Box} from "@mui/system";
import styles from "../components/DeviceItemCard.module.scss";
import DeviceRating from'./DeviceRating'
import axios from 'axios'; 
import { useCart } from '../CartContext';
import { slugify } from 'transliteration';


const DeviceItem = ({ device}) => {
  const {addToCart} = useCart()

    const [rating, setRating] = useState(device.rating); // Инициализируйте рейтинг начальным значением из пропсов
    const navigate = useNavigate();
    const goHome = () => {
      const path = `${DEVICE_ROUTE}/${device.id}/${encodeURIComponent(slugify(device.name, { lowercase: false }).toLowerCase())}`;
      navigate(path);
    };
    const handleRatingChange = async (newRating) => {
    try {
      const response = await axios.post('https://api.beddingshop.am/api/rating', {
        deviceId: device.id,
        rating: newRating,
      });

      console.log('Рейтинг успешно отправлен на сервер:', response.data);

      const updatedRating = await fetchDeviceRating(device.id);

      // Установите обновленное значение рейтинга в состояние
      setRating(updatedRating);
    } catch (error) {
      console.error('Ошибка при отправке рейтинга на сервер:', error);
    }
  };

  const fetchDeviceRating = async (deviceId) => {
    try {
      // Выполните запрос к серверу, чтобы получить текущий рейтинг по ID устройства
      const response = await axios.get(`https://api.beddingshop.am/api/rating/${deviceId}`);
      return response.data.rating; // Возвращаем значение рейтинга из ответа
    } catch (error) {
      console.error('Ошибка при загрузке рейтинга с сервера:', error);
      return rating; // Возвращаем текущее значение рейтинга в случае ошибки
    }
  };

  
    return (
        <Card
            style={{ boxShadow: 'none', width: 'auto', ...device.sty }}
            sx={{ height: '100%' }}
            className={`${styles.card} card`}
        >
            <Box
                component="div"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                }}
                className={styles.imageDiv}
            >
                <CardMedia
                    component="img"
                    image={process.env.REACT_APP_API_URL + device.img}
                    onClick={goHome}
                    alt="green iguana"
                    sx={{ width: '100%' }}
                />

                <div className={styles.bottom} onClick={() => {addToCart(device);}}>
                    <div className={styles.content}>
                        <div productid={device.id} >
                            <MdAddShoppingCart />
                            <div>Զամբյուղ</div>
                        </div>
                        
                    </div>
                </div>
            </Box>
            <CardContent className={styles.cardContent}>
                <div onClick={goHome} className={styles.title}>
                    {device.name}
                </div>
                
                <DeviceRating value={rating} onChange={handleRatingChange} /> {/* Используйте компонент DeviceRating */}
        <div className={styles.rating}>Ռեյտինգ: {rating}</div> {/* Отобразить рейтинг */}

        <p
                    style={device?.children && { color: '#EF837B' }}
                    className={styles.price}
                >
                     {device.price} {device?.children && device.children}֏
                </p>
            </CardContent>
        </Card>
    );
};

export default DeviceItem;
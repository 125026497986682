import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useContext, useState, useCallback } from 'react';
import styles from "./../Product/Styles.module.scss";
import { Breadcrumbs, Container, Grid, Link as BCLink, Rating } from '@mui/material';
import { AddShoppingCart, NavigateNext} from "@mui/icons-material";
import { HiMinus, HiPlus } from "react-icons/hi";
import { FaFacebookF, FaInstagram, FaTelegram, FaWhatsapp, FaYoutube } from "react-icons/fa";
import {fetchDevicesByType, fetchOneDevice} from "../../http/deviceAPI";
import {Context} from "../../index";
import DeviceList from "../../components/DeviceList";
import { Col, Row } from "react-bootstrap";
import { slugify } from "transliteration";
import DeviceRating from "../../components/DeviceRating";
import axios from 'axios';
import { useCart } from "../../CartContext";
import SimilarDeviceList from "./SimilarDeviceList";



const DevicePage = () => {
  const {cartItems,busketItems:myItems, addToCart} = useCart()
    const [device, setDevice] = useState({info: []})
    const [busketItems, setCartItemss] = useState()
    const [latinName, setLatinName] = useState(''); // Initialize latinName with an empty string
    const [deviceRating, setDeviceRating] = useState(0);
    

    
   
const { id, name } = useParams();    
const navigate = useNavigate();

const navigateToProduct = (productId) => {
    // Construct the URL for the product details page
    const latinName = slugify(name);
    const productPath = `/ankoxnayin-paraga/${productId}/${latinName}`;
    
    // Open a new browser window/tab with the product details page URL
    window.open(productPath, '_blank');
  };

  const fetchDevice = async (deviceId) => {
    try {
      const data = await fetchOneDevice(deviceId);
      setDevice(data);
    } catch (error) {
      console.error("Ошибка при получении данных о товаре:", error);
    }
  };

    
  const handleNavigate = (productId, productName) => {
    const latinName = slugify(productName, { lower: true, trim: true });
    const productPath = `/ankoxnayin-paraga/${productId}/${latinName}`;
    // Используйте navigate с replace: true для обновления URL без перезагрузки страницы
    navigate(productPath, { replace: true });
  };
   
useEffect(() => {
        
       
const latinName = slugify(name);
        
       
const devicePath = `/ankoxnayin-paraga/${id}/${latinName}`;
        
       
navigate(devicePath);
    }, [navigate, id, name]);

    
    useEffect(() => {
      fetchDevice(id);
    }, [id]); 
        
          
    const scrollToTop = () => {
        window.scrollTo({ top: 1010, behavior: 'smooth' });
      };
        
      const handleNavigateToSimilarProduct = (productId, productName) => {
        const productSlug = slugify(productName);
        navigate(`/ankoxnayin-paraga/${productId}/${productSlug}`);
        window.scrollTo({ top: 1010, behavior: 'smooth' });
      };
    
            
    
       
    // const devicePath = `/device/${id}/${latinName}`; 
    console.log(latinName)

    const handleFacebookClick = () => {
        window.open("https://www.facebook.com/bedder.shop", "_blank");
      };
      
      const handleTelegramClick = () => {
        window.open("https://t.me/BeddingShopam", "_blank");
      };
      
      const handleInstagramClick = () => {
        window.open("https://www.instagram.com/beddingshop.am/", "_blank");
      };
      
      const handleYoutubeClick = () => {
        window.open("https://www.youtube.com/@BeddingShopam/featured", "_blank");
      };
      
      const handleWhatsAppClick = () => {
        window.open("https://api.whatsapp.com/send?phone=37499111040", "_blank");
    };
    
    const addToBasket = (item) => {
        const busketItems = localStorage.getItem('busketItems');
        if(busketItems){
            let buskItms = JSON.parse(busketItems)
            if (buskItms.length && buskItms.some((cartItem) => cartItem.product.id === item.id)) {
                      
                        const updatedBuskItms = buskItms.map((cartItem) => {
                            if (cartItem.product.id === item.id) {
                              return {
                                ...cartItem,
                                amount: cartItem.amount + 1
                              };
                            }
                            return cartItem;
                          });
                    const addToLocalStore = JSON.stringify(updatedBuskItms)
                    localStorage.setItem('busketItems', addToLocalStore)
                    setCartItemss(addToLocalStore)

                        return;
                }
                    buskItms.push({product: item, amount: 1})
                    const addToLocalStore = JSON.stringify(buskItms)
                    localStorage.setItem('busketItems', addToLocalStore)
                    setCartItemss(addToLocalStore)
            }
            else{
                const items = JSON.stringify([
                    {product: item, amount: 1}
                ])
                localStorage.setItem('busketItems',items)
                 setCartItemss(items)
                 
            }
            window.location.reload()

    };





    
    const {cartList, setCartList} = useContext(Context);
    const [imageIndex, setImageIndex] = useState(0);
    const [revImgArr, setRevImgArr] = useState([]);
    const [amount, setAmount] = useState(1);
    const [bgPosition, setBgPosition] = useState("50% 50%");
    


    useEffect(()=>{
        fetchOneDevice(id).then(data => setDevice(data))
    },[])

    useEffect(() => {
      const loadData = async () => {
          try {
              const newData = await fetchOneDevice(id); // Замените эту функцию на вашу функцию для загрузки данных
              setDevice(newData);
              window.scrollTo(0, 0); // Прокрутка страницы к верху
          } catch (error) {
              console.error("Ошибка при загрузке данных о товаре:", error);
          }
      };
  
      loadData();
  }, [id]);

    const handleClick = (event) => {
        event.preventDefault();
        navigate(event.target.pathname)
    }

    const changeMainImage = useCallback((event) => {
        const selectedImgIndex = Number(event.currentTarget.getAttribute("imgindex"));
        setImageIndex(selectedImgIndex);
    }, [])

    function randomNumber() {
        return Math.floor(Math.random() * (5 - 1 + 1) + 1)
    }

    const changeAmount = (qty) => {
        if ((qty + amount) >= 1) {
            setAmount(prevState=> prevState + qty)
        }
    }

    // const addToCart = (event) => {
    //     const productID = event.currentTarget.id;
    //     if (cartList.some((cartItem) => cartItem.product === productID)) {
    //         setCartList((cart) =>
    //             cart.map((cartItem) =>
    //                 cartItem.product === productID
    //                     ? {
    //                         ...cartItem,
    //                         amount: cartItem.amount + amount
    //                     }
    //                     : cartItem
    //             )
    //         );
    //         return;
    //     }
    //     setCartList((cart) => [
    //         ...cart,
    //         {product: productID, amount: amount }
    //     ]);
    // }

    const zoom = (e) => {
          const { left, top, width, height } = e.target.getBoundingClientRect();
        const x = (e.clientX - left) / width * 100;
        const y = (e.clientY - top) / height * 100;
        e.target.style.backgroundPosition = `${x}% ${y}%`;
      };
    
      useEffect(() => {
        const myElement = document.getElementById("myElement");
        if (myElement) {
          myElement.addEventListener("mousemove", zoom);
        }
    
        return () => {
          // Cleanup: Remove the event listener when the component is unmounted
          if (myElement) {
            myElement.removeEventListener("mousemove", zoom);
          }
        };
      }, []);

      const handleRatingChange = async (newRating) => {
        try {
          const response = await axios.post('http://localhost:5000/api/rating', {
            deviceId: device.id, // ID устройства, к которому применяется рейтинг
            rating: newRating // Новое значение рейтинга
          });
      
          // Обработка успешного ответа сервера (если необходимо)
          console.log('Рейтинг успешно отправлен на сервер:', response.data);
      
          // Запросите обновленное значение рейтинга с сервера
          const updatedRating = await fetchDeviceRating(device.id);
      
          // Установите обновленное значение рейтинга в состояние
          setDeviceRating(updatedRating);
        } catch (error) {
          // Обработка ошибки при отправке на сервер (если необходимо)
          console.error('Ошибка при отправке рейтинга на сервер:', error);
        }
      };
      

      const fetchDeviceRating = async (deviceId) => {
        try {
          // Выполните запрос к серверу, чтобы получить текущий рейтинг по ID устройства
          const response = await axios.get(`https://api.beddingshop.am/api/rating/${deviceId}`);
          return response.data.rating; // Возвращаем значение рейтинга из ответа
        } catch (error) {
          console.error('Ошибка при загрузке рейтинга с сервера:', error);
          return deviceRating; // Возвращаем текущее значение рейтинга в случае ошибки
        }
      };
      
      useEffect(() => {
        // Внутри useEffect выполните загрузку начального рейтинга с сервера
        const loadInitialRating = async () => {
          try {
            // Выполните запрос к серверу, чтобы получить начальный рейтинг
            const response = await axios.get(`https://api.beddingshop.am/api/rating/${id}`);
            const initialRating = response.data.rating;
            setDeviceRating(initialRating);
          } catch (error) {
            console.error('Ошибка при загрузке начального рейтинга:', error);
          }
        };
      
        // Вызовите функцию загрузки начального рейтинга
        loadInitialRating();
      }, [id]);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      const [similarDevices, setSimilarDevices] = useState([]);

useEffect(() => {
    const fetchSimilarDevices = async () => {
        if (device && device.typeId) {
            try {
                // Assuming you have a function like fetchDevicesByType(typeId)
                const similarDevicesData = await fetchDevicesByType(device.typeId);
                setSimilarDevices(similarDevicesData);
            } catch (error) {
                console.error("Failed to fetch similar devices:", error);
            }
        }
    };

    fetchSimilarDevices();
}, [device]);
    return (
        
        <div>
            


                <Container sx={{pb: "1rem"}}>
                    <div role="presentation" onClick={handleClick}>
                        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNext fontSize="small" />} sx={{p: "14px 0"}}>
                            <BCLink
                                className={styles.brdHov}
                                underline="none"
                                color="black"
                                href="/"
                                fontSize={"14px"}
                                 >
                                    
                             Գլխավոր
                            </BCLink>
                            <BCLink
                                className={styles.brdHov}
                                underline="none"
                                color="#176e2a"
                                href="/basket"
                                aria-current="page"
                                fontSize={"14px"} >
                                Գնումների զամբյուղ
                            </BCLink>
                        </Breadcrumbs>
                    </div>
                    <Grid container spacing={2} sx={{justifyContent: "center"}}>
                        <Grid item md={6} className={styles.left}>
                            <div className={styles.images}>

                                     <img onClick={changeMainImage} imgindex={device.img} className={device.img === imageIndex ? styles.activeImage : styles.notActive} src={process.env.REACT_APP_API_URL + device.img} key={device.img} alt={"images"} />

                            </div>
                            <div className={styles.mainImage} onMouseMove={zoom} style={{backgroundImage: `url(${process.env.REACT_APP_API_URL + device.img})`, backgroundPosition: bgPosition}}>
                                <img src={process.env.REACT_APP_API_URL + device.img} alt="mainImage" />
                            </div>
                        </Grid>
                        <Grid item md={6}>
                            <div className={styles.right}>
                                <h1>{device.name}</h1>
                                <div className={styles.rate}>
      <DeviceRating value={deviceRating} onChange={handleRatingChange} />
      <p>({deviceRating} Ռեյտինգ)</p>

               {/*                     {device.info.map((info, index) =>*/}
               {/*<Row key={info.id} style={{background: index % 2 === 0 ? 'lightgray' : 'transparent', padding: 10}}>*/}
               {/*    {info.title}: {info.description}*/}
               {/*</Row>*/}
               {/*)}*/}
                                </div>

                                <p className={styles.price}> ֏ {device.price}</p>
                                {device.info.map((info, index) =>

                                <p className={styles.description} key={info.id}> {info.title} {info.description}</p>
                                    )}
                                <div className={styles.quantity}>
                                    <div>
                                        <button onClick={(event) => changeAmount(-1)}>
                                            <HiMinus />
                                        </button>
                                        <input type={"number"} min={1} value={amount} readOnly/>
                                        <button onClick={(event) => changeAmount(+1)}>
                                            <HiPlus />
                                        </button>
                                    </div>
                                </div>
                                <div className={styles.buttons}>
                                    <button className={styles.addToCardBtn} onClick={() => addToCart(device)} id={device.id}>
                                        <AddShoppingCart />
                                        ԶԱՄԲՅՈՒՂ
                                    </button>

                                </div>
                                <div className={styles.end}>

                                    <div className={styles.socials}>
                                        <p>Սոցիալական ցանցեր:</p>
                                        <div className={styles.socialsIcon}>
                                        <div onClick={handleFacebookClick}><FaFacebookF /></div>
                                        <div onClick={handleTelegramClick}><FaTelegram /></div>
                                        <div onClick={handleInstagramClick}><FaInstagram /></div>
                                        <div onClick={handleYoutubeClick}><FaYoutube /></div>
                                        <div onClick={handleWhatsAppClick}><FaWhatsapp /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        
                    </Grid>
                    <Grid>
    <div className={styles.similar}>Նմանատիպ ապրանքներ</div>
    <Row className="mt-2 padding: 20px">
        <Col md={12}>
            {/* Pass similarDevices as props */}
            <SimilarDeviceList devices={similarDevices} onNavigate={handleNavigateToSimilarProduct} />
        </Col>
    </Row>
</Grid>
                    
                </Container>
                
            
        </div>
    );
}

export default React.memo(DevicePage);

// CartContext.js
import React, { createContext, useContext, useState } from 'react';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [busketItems, setCartItemss] = useState()
  const addToCart = (item) => {
    if (busketItems?.length) {
      if (busketItems.length && busketItems.some((cartItem) => cartItem.product.id === item.id)) {
        const updatedBuskItms = busketItems.map((cartItem) => {
          if (cartItem.product.id === item.id) {
            return {
              ...cartItem,
              amount: cartItem.amount + 1,
            };
          }
          return cartItem;
        });
        setCartItemss(updatedBuskItms);
        return;
      } else {
        setCartItemss([...busketItems, { product: item, amount: 1 }]);
      }
    } else {
      setCartItemss([{ product: item, amount: 1 }]);
    }
  };
  
  const removeFromCart = (itemId) => {
    const updatedCart = busketItems.filter((item) => item.product.id !== itemId);
    setCartItemss(updatedCart);
  };

  const clearCart = () => {
    setCartItems([]);
  };

  return (
    <CartContext.Provider value={{ cartItems, busketItems, addToCart, removeFromCart, clearCart }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  return useContext(CartContext);
};

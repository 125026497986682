import React, { useEffect } from 'react';
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./components/AppRouter";
import { observer } from "mobx-react-lite";
import Footer from "./components/Footer/Footer";
import './App.css';
import NevNavbar from "./components/NavBar/NewNavBar";
import Trait from './components/Trait/Trait';
import DealsOutlet from './pages/Home/Deals&Outlet/Deals&Outlet';
import LeftTypeBar from './components/LeftTypeBar';
import SocialAppBar from './components/NavBar/SocialAppBar';

const App = observer(() => {
    useEffect(() => {
        // Добавление Yandex.Metrika
        const addYandexMetrika = () => {
            (function(m, e, t, r, i, k, a) {
                m[i] = m[i] || function() {
                    (m[i].a = m[i].a || []).push(arguments);
                };
                m[i].l = 1 * new Date();
                k = e.createElement(t);
                a = e.getElementsByTagName(t)[0];
                k.async = 1;
                k.src = r;
                a.parentNode.insertBefore(k, a);
            })(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

            window.ym(97286761, "init", {
                clickmap: true,
                trackLinks: true,
                accurateTrackBounce: true,
                webvisor: true,
            });
        };

        // Добавление Google Tag Manager
        const addGoogleTagManager = () => {
            const script1 = document.createElement("script");
            script1.async = true;
            script1.src = "https://www.googletagmanager.com/gtag/js?id=G-5FGJPG6Y10";
            document.head.appendChild(script1);

            const script2 = document.createElement("script");
            script2.innerHTML = `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-5FGJPG6Y10');
            `;
            document.head.appendChild(script2);
        };

        // Вызов функций для добавления скриптов
        addYandexMetrika();
        addGoogleTagManager();
    }, []);

    return (
        <BrowserRouter>
            <NevNavbar />
            <SocialAppBar />
            <LeftTypeBar />
            <AppRouter />
            <Trait />
            <DealsOutlet />
            <Footer />
        </BrowserRouter>
    );
});

export default App;

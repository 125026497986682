import React from 'react';
import styles from "./Product/Styles.module.scss";
const About = () => {
  return (
    
    
      <div className={styles.About}>
      <h2>Մեր մասին</h2>
      <p>Մենք հասկանում ենք, որ ժամանակն էական նշանակություն ունի, և մենք ցանկանում ենք ապահովել, որ դուք հնարավորինս արագ ստանաք ձեր պատվերները: 

Դուք կարող եք ընտրել ձեր նախընտրած անկողնային պարագան մեր էջում, նշել առաքման հասցեն և կոնտակտային հեռախոսահամարը և հաշված ժամերի ընթացքում ստանալ պատվերը ։</p>
    </div>
  );
};


     
export default About;
import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Context } from '../index';
import { Pagination } from 'react-bootstrap';

const Pages = observer(() => {
  const { device } = useContext(Context);
  const pageCount = Math.ceil(device.totalCount / device.limit);
  const currentPage = device.page;
  
  const scrollToTop = () => {
    window.scrollTo({ top: 1010, behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToTop();
  }, [currentPage]);

  const generatePaginationItems = () => {
    const pages = [];
    let startPage = 1;
    let endPage = pageCount;

    const pageLimit = 5; // Максимальное количество отображаемых страниц
    const sidePages = Math.floor(pageLimit / 2);

    if (pageCount > pageLimit) {
      // Определение начальной и конечной страницы
      if (currentPage <= sidePages) {
        endPage = pageLimit;
      } else if (currentPage + sidePages >= pageCount) {
        startPage = pageCount - pageLimit + 1;
      } else {
        startPage = currentPage - sidePages;
        endPage = currentPage + sidePages;
      }
    }

    // Первая страница и эллипсис (если нужно)
    if (startPage > 1) {
      pages.push(<Pagination.First key="first" onClick={() => device.setPage(1)}/>);
      pages.push(<Pagination.Ellipsis key="ellipsis1" />);
    }

    // Основные страницы
    for (let page = startPage; page <= endPage; page++) {
      pages.push(
        <Pagination.Item
          key={page}
          active={currentPage === page}
          onClick={() => {
            device.setPage(page);
            scrollToTop();
          }}
        >
          {page}
        </Pagination.Item>
      );
    }

    // Эллипсис и последняя страница (если нужно)
    if (endPage < pageCount) {
      pages.push(<Pagination.Ellipsis key="ellipsis2" />);
      pages.push(<Pagination.Last key="last" onClick={() => device.setPage(pageCount)}/>);
    }

    return pages;
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Pagination className="mt-5">
        {generatePaginationItems()}
      </Pagination>
    </div>
  );
});
export default Pages;

import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Context } from '../index';
import { Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { slugify } from 'transliteration';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link, useNavigate } from 'react-router-dom';
import '../components/LeftTypeBar.css';

const LeftTypeBar = observer(() => {
  const { device } = useContext(Context);
  const navigate = useNavigate();

  const handleTypeClick = (type) => {
    device.setSelectedType(type);
    const latinName = slugify(type.name, { lowercase: false });
    const newPath = `/products/${type.id}/${encodeURIComponent(latinName).toLowerCase()}`;
    navigate(newPath);
  };
  const menuItemStyle = {
    /* Add your MenuItem styles here */
    // backgroundColor: 'lightgray',
    
    // background:'#176e2a',
    // color:'#fff',
    // border: '1px ',
    // transition: 'background-color 0.3s ease 0s', // Add transition with duration and easing
    // '&:hover': {
    //   backgroundColor: '#fff',
    // },
  };
  const menuItemHoverStyle = {
  backgroundColor: '#fff',
};
  return (
    <>
      

      <div
  className="menu"
  
      
>
        {device?.types.map((type) => (
          <MenuItem style={menuItemStyle} className="item" key={type.id}>
          <Link
              to={`/products/${type.id}/${encodeURIComponent(slugify(type.name, { lowercase: false }).toLowerCase())}`}
              onClick={() => handleTypeClick(type)}
              className="linkStyle"
            >
              <span className="nameStyle">{type.name}</span>
            </Link>
          </MenuItem>
        ))}
      </div>
    </>
  );
});

export default LeftTypeBar;

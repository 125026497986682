import React from 'react';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { Context } from '../index';
import { Row, Card } from 'react-bootstrap';

const BrendBar = observer(() => {
  const { device } = useContext(Context);
  return (
    <Row className="p-2 d-flex justify-content-around">
      {device &&
        device.brends.map((brend) => (
          <Card
            style={{ cursor: 'pointer' }}
            key={brend.id}
            className={`p-2 w-auto ${brend.id === device.selectedBrend.id ? 'focus-card' : ''}`}
            onClick={() => device.setSelectedBrend(brend)}
            border={brend.id === device.selectedBrend.id ? 'green' : 'light'}
          >
            {brend.name}
          </Card>
        ))}
    </Row>
  );
});

export default BrendBar;


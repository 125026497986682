import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import styles from "./Styles.module.scss";
import { Box } from '@mui/material';
import { ArrowRightAlt } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import slide1 from '../../../../../src/img/slider 780 2.jpg';
import slide2 from '../../../../../src/img/slider  2 n.jpg';

const HeaderBanner = () => {
    return (
        <Swiper
            navigation={true} modules={[Navigation]}
            className={`${styles.swiper} swiperC`}
            >
            <SwiperSlide className={styles["swiper-slide"]} position='relative'>
                <Box component="div" className={styles.headerSliderText}>
                    <h3>Լավագույն վաճառք</h3>
                    <h1>Անկողնային <br /> պարագաներ</h1>
                    <p>
                        <sup className={styles.firstSup}>Սկսած:</sup>
                        <span>֏ 2999<sup className={styles.secondSup}>․99</sup></span>
                    </p>
                    <Link to={"/products/18/carolina-mekteghanots"} className={styles.bannerButton}>
                    Տեսնել բոլորը <ArrowRightAlt sx={{ml: 1, fontSize: 22}} />
                    </Link>
                </Box>
                <Box component="img" className={styles.photo1} src={slide1} />
            </SwiperSlide>
            <SwiperSlide className={styles["swiper-slide"]} position='relative'>
                <Box component="div" className={styles.headerSliderText}>
                    <h3>Հյուրանոցային սատին</h3>
                    <h1>Անկողնային<br />պարագաներ</h1>
                    <p>
                        <sup className={styles.firstSup} style={{textDecoration: "line-through", color: "#333"}}>֏7000</sup>
                        <span>֏ 2999<sup className={styles.secondSup} style={{top: "-0.2em"}}>.99</sup></span>
                    </p>
                    <Link to={"/products/10/zhat-erkteghanots"} className={styles.bannerButton}>
                    Տեսնել բոլորը <ArrowRightAlt sx={{ml: 1, fontSize: 22}} />
                    </Link>
                </Box>
                <Box component="img" className={styles.photo2} src={slide2} />
            </SwiperSlide>
        </Swiper>
    );
}

export default HeaderBanner;
import React, {  useEffect, useState } from "react";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import "./Style.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { useCart } from "../../CartContext";


const MainPage = () => {
  const {busketItems:basketProducts, removeFromCart} = useCart()
  const [sortBy, setSortBy] = useState("price");
  const [values, setValues] = useState({
    name:'',
    address:'',
    phoneNumber:''
  })
  const [errMessage, setErrMessage] = useState({
    name:'',
    address:'',
    phoneNumber:''
  });

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
  };
  const handleChange = (e) => {
    const {value, name} = e.target
    setValues((prev)=> {return {...prev, [name]:value}})
    setErrMessage((prev) => {return {...prev, [name]:''}})
  }
  // const sortBasketProducts = () => {
  //   switch (sortBy) {
  //     case "price":
  //       return [...basketProducts].sort((a, b) => a.product.price - b.product.price);
  //     case "name":
  //       return [...basketProducts].sort((a, b) => a.product.name.localeCompare(b.product.name));
  //     default:
  //       return basketProducts;
  //   }
  // };
 
  const calculateTotalPrice = () => {
    return basketProducts?.reduce(
      (total, item) => total + item.product.price * item.amount,
      0
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
    const handleCheckout = async () => {
        const {name, address} = values

        const phoneNumber = values.phoneNumber;
        const phoneNumberRegex = /^\d{9}$/;

        let valid = true;

        const errMessage = {
            name:'',
            address:'',
            phoneNumber:''
        }

        if (!name.trim()) {
          errMessage.name= "Անունը պարտադիր է";
          valid = false;
        } 
      
        if (!address.trim()) {
          errMessage.address="Հասցեն պարտադիր է";
          valid = false;
        }
      
        if (!phoneNumber.trim()) {
          errMessage.phoneNumber = 'Հեռախոսահամարը պարտադիր է';
          valid = false;
        } else if (!phoneNumberRegex.test(phoneNumber)) {
          errMessage.phoneNumber = 'Հեռախոսահամարը պետք է ունենա 9 թիվ';
          valid = false;
        }
        // if (!/^\+374 \d{2} \d{2} \d{2} \d{2}$/.test(phoneNumber)) {
        //   errMessage.phoneNumber =  "Սխալ հեռախոսահամարի ձևաչափ";
        //   valid = false;
        // } 
        
        setErrMessage(errMessage)
        if (valid) {
          const orderDetails = {
            basketProducts,
            totalPrice: calculateTotalPrice(),
            name,
            address,
            phoneNumber,
          };
        try {
          // Отправка данных на бэкенд
         await axios.post('https://api.beddingshop.am/submit-order', orderDetails);
          // Отображение успешного сообщения или выполнение других действий
          alert(`Ձեր պատվերը ընդունվել է! Հարգելի ${name},  Մեր մասնագետները Ձեզ հետ կկապնվեն Շնորհակալություն`);
          // localStorage.removeItem('busketItems');
          removeFromCart(); 
      setValues({
        name: '',
        address: '',
        phoneNumber: ''
      });

        } 
        catch (error) {
          console.error('Error sending order:', error);
        }
      }
    };
    
    
  
    

  return (
    <>
    
      <section className="h-100 h-custom" style={{ backgroundColor: "#eee" }}>
        <MDBContainer className="py-5 h-100">
        <MDBRow className="justify-content-center align-items-center h-100" style={{ paddingTop: '20px', paddingLeft: '50px' }}>

            <MDBCol>
              <MDBCard>
                <MDBCardBody className="p-4">
                  <MDBRow>
                    <MDBCol lg="7">
                      <MDBTypography tag="h5">
                        <Link to="/" className="text-body">
                          <MDBIcon fas icon="long-arrow-alt-left me-2" /> 
                          Շարունակել գնումները
                        </Link>
                      </MDBTypography>
                      <hr />

                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <div>
                          <p className="mb-1">Գնումների զամբյուղ</p>
                          <p className="mb-0">
                          Դուք ունեք {basketProducts?.length} ապրանքներ Ձեր զամբյուղում
                          </p>
                        </div>
                        <div>
                        <div>
                          <p className="mb-0">
                            <span className="text-muted">Sort by:</span>
                            <select value={sortBy} onChange={handleSortChange} className="form-select form-select-sm">
                              <option value="price">Price</option>
                              <option value="name">Name</option>
                              {/* Add more options for other sorting options */}
                            </select>
                          </p>
                        </div>
                        </div>
                      </div>

                      <MDBCard className="mb-3">
                        {basketProducts?.length === 0 ? (
                          <MDBCardBody>
                            <p>Ձեր զամբյուղը դատարկ է.</p>
                          </MDBCardBody>
                        ) : (
                          basketProducts?.map((item) => (
                            <MDBCardBody key={item.product.id}>
                              <div className="d-flex justify-content-between">
                                <div className="d-flex flex-row align-items-center">
                                  <div>
                                    <MDBCardImage
                                      src={
                                        process.env.REACT_APP_API_URL +
                                        item.product.img
                                      }
                                      fluid
                                      className="rounded-3"
                                      style={{ width: "65px" }}
                                      alt="Shopping item"
                                    />
                                  </div>
                                  <div className="ms-3">
                                    <MDBTypography tag="h6">
                                      {item.product.name}
                                    </MDBTypography>
                                    <p className="small mb-0">
                                      
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex flex-row align-items-center">
                                  <div style={{ width: "50px" }}>
                                    <MDBTypography
                                      tag="h5"
                                      className="fw-normal mb-0"
                                    >
                                      {item.amount}
                                    </MDBTypography>
                                  </div>
                                  <div style={{ width: "80px" }}>
                                    <MDBTypography tag="h6" className="mb-0">
                                     ֏ {item.product.price}
                                    </MDBTypography>
                                  </div>
                                  <a
                                    href="#!"
                                    style={{ color: "#cecece" }}
                                  >
                                    <MDBIcon fas icon="trash-alt" />
                                    <Button
                                      variant="outlined"
                                      startIcon={<DeleteIcon />}
                                      onClick={() =>
                                        removeFromCart(item.product.id)
                                      }
                                      style={{ border: "none" }}
                                    >
                                      <div className="delete">Ջնջել</div>
                                    </Button>
                                  </a>
                                </div>
                              </div>
                            </MDBCardBody>
                          ))
                        )}
                      </MDBCard>
                    </MDBCol>

                    <MDBCol lg="5">
                    <MDBCard style={{ backgroundColor: '#176e2a' }} className="text-white rounded-3">

                        <MDBCardBody>
                          <div className="d-flex justify-content-between align-items-center mb-4">
                            <MDBTypography tag="h5" className="mb-0">
                              ԳՐԱՆՑԵԼ ՊԱՏՎԵՐ
                            </MDBTypography>
                          </div>

                          <a href="#!" type="submit" className="text-white">
                            <MDBIcon
                              fab
                              icon="cc-mastercard fa-2x me-2"
                            />
                          </a>
                          <a href="#!" type="submit" className="text-white">
                            <MDBIcon fab icon="cc-visa fa-2x me-2" />
                          </a>
                          <a href="#!" type="submit" className="text-white">
                            <MDBIcon fab icon="cc-amex fa-2x me-2" />
                          </a>
                          <a href="#!" type="submit" className="text-white">
                            <MDBIcon fab icon="cc-paypal fa-2x me-2" />
                          </a>

                          <form className="mt-4">
                          Անուն և Ազգանուն *
                <span style={{color:'red'}}>{errMessage.name}</span>

                <MDBInput
                  required={true}
                  className="mb-4"
                  type="text"
                  size="lg"
                  name="name"
                  onChange={handleChange}
                  value={values.name}
                  // onBlur={() => {
                  //   if (!name.trim()) {
                  //     setNameError("Անունը պարտադիր է");
                  //   } else {
                  //     setNameError("");
                  //   }
                  // }}
                  contrast
                />
                             Հասցե *
                <span style={{color:'red'}}>{errMessage.address}</span>

                <MDBInput
                  className="mb-4"
                  type="text"
                  size="lg"
                  name="address"
                  onChange={handleChange}
                  value={values.address}
                  // onBlur={() => {
                  //   if (!address.trim()) {
                  //     setAddressError("Հասցեն պարտադիր է");
                  //   } else {
                  //     setAddressError("");
                  //   }
                  // }}
                  error={errMessage.address} // Show the error message using the error prop
                  contrast
                />
                             Հեռախոսահամար *
                <span style={{color:'red'}}>{errMessage.phoneNumber}</span>

                <MDBInput
                placeholder='099000000'
                  className="mb-4"
                  type="text"
                  size="lg"
                  name="phoneNumber"
                  onChange={handleChange}
                  value={values.phoneNumber}
                  // onBlur={() => {
                  //   if (!phoneNumber.trim()) {
                  //     setPhoneNumberError("Հեռախոսահամարը պարտադիր է");
                  //   } else if (!/^\+374 \d{2} \d{2} \d{2} \d{2}$/.test(phoneNumber)) {
                  //     setPhoneNumberError("Սխալ հեռախոսահամարի ձևաչափ");
                  //   } else {
                  //     setPhoneNumberError("");
                  //   }
                  // }}
                  error={errMessage.phoneNumber} // Show the error message using the error prop
                  contrast
                />
                

                          </form>

                          <hr />

                          <div className="d-flex justify-content-between">
                            <p className="mb-2">Ենթագումար</p>
                            <p className="mb-2">
                              {calculateTotalPrice()} ֏
                            </p>
                          </div>

                          <div className="d-flex justify-content-between">
                            <p className="mb-2">Առաքում Երևանում</p>
                            <p className="mb-2"> 0 ֏ (Անվճար)</p>
                          </div>

                          <div className="d-flex justify-content-between">
                            <p className="mb-2">Առաքում մարզերում</p>
                            <p className="mb-2">Սկսած  1000 ֏-ից</p>
                          </div>

                          <div className="d-flex justify-content-between">
                            <p className="mb-2">Ընդհանուր</p>
                            <p className="mb-2">
                              {calculateTotalPrice()} ֏
                            </p>
                          </div>

                          <MDBBtn
                color="green"
                block
                size="lg"
                onClick={handleCheckout}
              >
                
                <div  className="d-flex justify-content-between">
  <span>{calculateTotalPrice()} ֏ </span>
  <i className="fas fa-long-arrow-alt-right ms-2"></i>
  <span
    onClick={handleCheckout}
    style={{ cursor: 'pointer', textDecoration: 'underline' }}
  >
    ՊԱՏՎԻՐԵԼ
  </span>
</div>

                
              </MDBBtn>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    </>
  );
};

export default MainPage;

import React, { useContext, useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Context } from '../index';
import { Button, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { slugify } from 'transliteration';
import { Link, useNavigate } from 'react-router-dom';

const TypeBar = observer(() => {
  const { device } = useContext(Context);
  const [openMenu, setOpenMenu] = useState(false);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  const handleTypeClick = (type) => {
    device.setSelectedType(type);
    const latinName = slugify(type.name, { lowercase: false });
    const newPath = `/products/${type.id}/${encodeURIComponent(latinName).toLowerCase()}`;
    navigate(newPath);
    setOpenMenu(false);
  };

  const handleMenuToggle = () => {
    setOpenMenu(!openMenu);
  };

  useEffect(() => {
    const handleClickOutsideMenu = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutsideMenu);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideMenu);
    };
  }, []);

  return (
    <>
      <Button
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{ mr: 2, backgroundColor: '#176e2a', color: 'white' }}
        onClick={handleMenuToggle}
        startIcon={<MenuIcon />}
      />

      <Menu
        anchorReference="anchorPosition"
        anchorPosition={{ top: 0, left: 0 }}
        open={openMenu}
        onClose={() => setOpenMenu(false)}
        sx={{
          '.MuiPaper-root': { // This targets the Paper component inside Menu
            backgroundColor: '#176e2a', // Set your desired background color
            color: 'white', // Ensuring the text color is white for better readability
            // Add more styles as needed
          }
        }}
      >
        {device?.types.map((type) => (
          <MenuItem key={type.id} onClick={() => handleTypeClick(type)}>
            <Link
              to={`/products/${type.id}/${encodeURIComponent(
                slugify(type.name, { lowercase: false }).toLowerCase()
              )}`}
              style={{ textDecoration: 'none', color: 'white', textTransform: 'uppercase' }}
            >
              {type.name}
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
});

export default TypeBar;

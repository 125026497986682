// SimilarDeviceList.js
import React, { useContext } from 'react';
import DeviceItem from '../../components/DeviceItem';
import { Row } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';
import { useNavigate } from 'react-router-dom';
import { slugify } from 'transliteration';

const SimilarDeviceList = observer(() => {
    const { device } = useContext(Context);
    const navigate = useNavigate(); // Используйте хук useNavigate

    // Функция для навигации
    const handleNavigate = (productId, productName) => {
        const path = `/ankoxnayin-paraga/${productId}/${slugify(productName)}`;
        navigate(path, { replace: true });
    };

    return (
        <Row className="d-flex justify-content-center">
            {device.devices.map(device => (
                // Передаем handleNavigate как onClick и используем bind для предварительной передачи параметров
                <DeviceItem key={device.id} device={device} onClick={() => handleNavigate(device.id, device.name)} />
            ))}
        </Row>
    );
});

export default SimilarDeviceList;

import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Context } from '../index';
import { useParams } from 'react-router-dom';
import { fetchDevice } from '../http/deviceAPI';
import { Row } from 'react-bootstrap';
import DeviceItem from './DeviceItem'; // Import the DeviceItem component
import Pages from './Pages';
import styles from './ProductsByTypePage.module.css'; // Import the CSS module

const ProductsByTypePage = observer(() => {
  const { device } = useContext(Context);
  const { typeId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchDevice(typeId, null, device.page, device.limit);
        device.setDevices(response.rows);
        device.setTotalCount(response.count);
      } catch (error) {
        console.error('Error fetching devices:', error);
      }
    };
  
    fetchData();
  }, [typeId, device.page, device.limit]);

  // Adjusted useEffect to also trigger on device.page changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [device.page]); // Now, this effect will re-run whenever the current page changes

  return (
    <div>
      <h2 className={styles.pageHeader}>
        {device.selectedType?.name}
      </h2>
      <div className="col-md-12">
        <Row className={`d-flex ${styles.deviceRow}`}>
          {device.devices
            .filter((device) => device.typeId === Number(typeId))
            .map((filteredDevice) => (
              <DeviceItem
                key={filteredDevice.id}
                device={filteredDevice}
              />
            ))}
        </Row>
        <Pages />
      </div>
    </div>
  );
});

export default ProductsByTypePage;

import { ArrowRightAlt } from '@mui/icons-material';
import { Container } from '@mui/system';
import React, { memo, useContext } from 'react';
import styles from "./Styles.module.scss";

import Counter from './Counter';
import { Link } from 'react-router-dom';

import {Context} from "../../../index";

const DealsOutlet = () => {

    return (
        <div className={styles.main}>
            <Container>
                <div className={styles.top}>
                    <h1>Ակցիաներ</h1>
                    <p>Ներքնակների և այլ պարագաների ակցիաներ Bedding Shop -ում.</p>
                </div>
                <div className={styles.body}>
                    <div className={styles.father}>
                        <div  className={styles.ghPic}>
                            <div className={styles.body1}>
                                <h2>Շաբաթվա ակցիաներ.</h2>
                                <h4>Սահմանափակ քանակությամբ.</h4>
                            </div>
                            <div className={styles.body2}>
                                <p className={styles.title}>Ամենասիրված ապրանքները դեռ կարող եք ձեռք բերել ԶԵՂՉՎԱԾ արժեքով</p>
                                <p>֏ 4999.00 <span>Եղել է ֏ 7999.99</span></p>
                                <Link to={`/ankoxnayin-paraga/6/dscvsdv`}>
                                Գնել հիմա 
                                    <ArrowRightAlt sx={{ml: ".7rem", fontSize: 22, verticalAlign: "middle"}} />
                                </Link>
                            </div>
                            <Counter />
                        </div>
                        <div className={styles.rightProducts}>
                            <div className={styles.rightProduct}>
                               
                            </div>
                            <div className={styles.rightProduct}>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default memo(DealsOutlet);